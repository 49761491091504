<template>
  <a-modal
    v-model="show"
    :centered="true"
    :keyboard="false"
    :footer="null"
    :width="1200"
    @cancel="handleCancleClick"
    title="活动详情信息"
  >
    <a-layout class="activity-info">
      <a-layout-sider class="activity-info-side">
        <a-avatar shape="square" style="margin-bottom: 10px" :size="150" icon="user" :src="image"/><br/>
        <!-- <div class="empno-label">活动封面</div> -->
        <p><my-icon type="community"/>举办社群：{{activityData.name ? activityData.name : '暂无'}}</p>
        <p><my-icon type="range"/>活动范围: {{ range }}</p>
        <p><my-icon type="couples"/>面向对象：{{activityData.listName ? activityData.listName : '暂无'}}</p>
        <p><my-icon type="userName"/>限制人数：{{activityData.maxParticipants ? `${activityData.maxParticipants}人` : '暂无'}}</p>
        <p><a-icon type="user"/>报名人数：{{activityData.nowParticipants ? `${activityData.nowParticipants}人` : '暂无'}}</p>
        <p><a-icon type="clock-circle"/>开始时间：{{activityData.beginTime ? activityData.beginTime : '暂无'}}</p>
        <p><my-icon type="sponsor"/>参加人员：</p>
        <a-tag style="margin: 0 5px 5px" v-for="(data, index) of activityData.users" :key="index">
          {{ data.username }}
        </a-tag>
      </a-layout-sider>
      <a-layout>
        <a-layout-header style="margin-bottom: 10px" class="activity-info-header">
          <span><my-icon type="name"/>活动标题：</span>&nbsp;
          <span :title="activityData.title">{{activityData.title}}</span>
        </a-layout-header>
        <a-layout-content class="activity-info-content">
          <a-row style="margin-bottom: 10px" >
            <a-col :span="24">
              <span><my-icon type="desc"/>活动内容：</span><br/>
              <span class="info">{{activityData.content ? activityData.content : '暂无'}}</span>
            </a-col>
          </a-row>
          <a-row style="margin-bottom: 10px" >
            <a-col :span="24">
              <span><my-icon type="news"/>新闻内容：</span><br/>
              <span class="info" v-html="activityData.news ? activityData.news : '暂无'"></span>
            </a-col>
          </a-row>
          <a-row v-if="enclosure.length">
            <a-col :span="24">
              <span><my-icon type="jobRequirements"/>附件：</span><br/>
              <div v-for="(item, index) in enclosure" :key="index">
                <a :href="`${$store.state.baseinfo.filedomain}${item.url}`">{{$store.state.baseinfo.filedomain}}{{item.url}}</a>
              </div>
            </a-col>
          </a-row>
        </a-layout-content>
      </a-layout>
    </a-layout>
  </a-modal>
</template>

<script>
export default {
  name: 'ActivityInfo',
  props: {
    infoVisiable: {
      require: true,
      default: false
    },
    activityData: {
      require: true
    },
  },
  computed: {
    show: {
      get: function () {
        return this.infoVisiable
      },
      set: function () {
      }
    },
    image: {
      get: function () {
        return this.activityData.image?`${this.$store.state.baseinfo.filedomain}${this.activityData.image}`:'';
      },
      set: function () {
      }
    },
    range: {
      get: function () {
        return this.activityData.activityRange ? "外部" : "内部";
      }
    },
    enclosure: {
      get: function () {
        return this.activityData.enclosure?JSON.parse(this.activityData.enclosure):[]
      },
      set: function () {}
    }
  },
  methods: {
    handleCancleClick () {
      this.$emit('close')
    },
  }
}
</script>

<style lang="less" scoped>
@import "ActivityInfo";
i {
  margin-right: .8rem;
}
</style>

