<template>
  <a-card :bordered="false" class="card-area">

    <div class="page-form-advanced-search">
      <a-form class="ant-advanced-search-form">
        <a-row :gutter="24">
          <a-col :span="8">
            <a-form-item label="活动标题">
              <a-input v-model="queryParams.title" placeholder="活动标题" />
            </a-form-item>
          </a-col>
          <a-col :span="5">
            <a-form-item label="举办社群">
              <a-select v-model="queryParams.communityId">
                <a-select-option :value="item.communityId" v-for="item in communityList" :key="item.communityId">{{ item.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
				<a-row>
				  <a-col :span="12"></a-col>
				  <a-col :span="12" :style="{ textAlign: 'right' }">
				    <a-button type="primary" @click="search">查询</a-button>
				    <a-button style="margin-left: 8px" @click="reset">重置</a-button>
				  </a-col>
				</a-row>
      </a-form>
    </div>

    <div class="operator">
      <a-button type="primary" ghost @click="addRecord">新增</a-button>
      <a-button @click="batchDelete">删除</a-button>
    </div>
    
    <a-table 
      ref="TableInfo" 
      :scroll="{ x: 900 }" 
      bordered 
      :loading="loading"
      :pagination="pagination" 
      @change="handleTableChange" 
      :columns="columns" 
      :data-source="dataSource"
      :rowKey="(record) => record.id"
      :row-selection="{selectedRowKeys: selectedRowKeys, onChange: onSelectChange}"
    >
      <template slot="userlist" slot-scope="list, record">
        <template v-for="(data, index) of list">
          <a-tag
            style="margin: 0 5px 5px"
            :key="index"
            v-if="record.showAll || index < 6"
          >
            {{ data.username }}
          </a-tag>
        </template>

        <span
          style="white-space: nowrap; color: #3db6fc; cursor: pointer;"
          v-if="list.length > 6"
          @click="record.showAll = !record.showAll"
        >
          {{ record.showAll ? "折叠▲" : "展开▼" }}
        </span>
      </template>
      <template slot="operation" slot-scope="text, record">
        <a-icon type="edit" theme="twoTone" twoToneColor="#4a9ff5" @click="editRecord(record)" title="修改"></a-icon>
        &nbsp;
        <a-icon type="eye" theme="twoTone" twoToneColor="#42b983" @click="view(record)" title="详情"></a-icon>
      </template>
    </a-table>
    <ActivityEdit ref="activityEdit" @close="handleEditClose" @success="handleEditSuccess" :editVisiable="editVisiable" />
    <ActivityAdd ref="activityAdd" @close="handleAddClose" @success="handleAddSuccess" :addVisiable="addVisiable" />
    <ActivityInfo :infoVisiable="infoVisiable" :activityData="activityData" @close="handleInfoClose" />
  </a-card>
</template>

<script>
import ActivityEdit from "./ActivityEdit"
import ActivityAdd from "./ActivityAdd"
import ActivityInfo from "./ActivityInfo"

const columns = [
  {
    title: "活动对象",
    dataIndex: "userlist",
    scopedSlots: { customRender: "userlist" },
  },
  {
    title: "标题",
    dataIndex: "title",
    // width: 300
    // fixed: 'left',
    // ellipsis: true
  },
  {
    title: "举办社群",
    dataIndex: "name",
    width: 150
    // ellipsis: true
  },
  {
    title: "已参加人数（人）",
    dataIndex: "nowParticipants",
    width: 150
    // ellipsis: true
  },
  {
    title: "限制人数（人）",
    dataIndex: "maxParticipants",
    width: 150
    // ellipsis: true
  },
  // {
  //   title: "活动内容",
  //   dataIndex: "content",
  //   width: 300,
  //   ellipsis: true
  // },
  // {
  //   title: "活动新闻",
  //   dataIndex: "news",
  //   width: 300,
  //   ellipsis: true
  // },
  {
    title: "开始时间",
    dataIndex: "beginTime",
    width: 150
    // ellipsis: true
  },
  // {
  //   title: "活动封面",
  //   dataIndex: "image",
  //   width: 500
  //   // ellipsis: true
  // },
  // {
  //   title: "附件",
  //   dataIndex: "enclosure",
  //   width: 500
  //   // ellipsis: true
  // },
  {
    title: "操作",
    dataIndex: "operation",
    fixed: "right",
    scopedSlots: { customRender: 'operation' },
    width: 70
  }
];

export default {
  components: {ActivityEdit, ActivityAdd, ActivityInfo},
  data() {
    return {
      columns,
      dataSource: [],
      selectedRowKeys: [],
      communityList: [],
      queryParams: {
        title: "",
        communityId: null
      },
      paginationInfo: null,
      pagination: {
        pageSizeOptions: ['10', '20', '30', '40', '100'],
        defaultCurrent: 1,
        defaultPageSize: 10,
        showQuickJumper: true,
        showSizeChanger: true,
        showTotal: (total, range) => `显示 ${range[0]} ~ ${range[1]} 条记录，共 ${total} 条记录`
      },
      loading: false,
      editVisiable: false,
      addVisiable: false,
      infoVisiable: false,
      activityData: {}
    };
  },
  mounted() {
    this.fetch();
    this.$get("community/list").then(r => {
      // console.log("获取所有的用户组", r.data);
      this.communityList = r.data || [];
    });
  },
  methods: {
    view(record) {
      // console.log("需要显示详情窗口的数据 ==> ", record);
      // record.image = record.image?JSON.parse(record.image)[0]['url']:'';
      this.activityData = record;
      this.infoVisiable = true;
    },
    handleInfoClose() {
      this.infoVisiable = false;
    },
    editRecord(record) {
      this.editVisiable = true;
      this.$refs.activityEdit.setFormFields(record);
    },
    handleEditClose() {
      this.editVisiable = false;
    },
    handleEditSuccess() {
      this.editVisiable = false;
      this.$message.success("修改社群活动相关成功！");
      this.fetch();
    },
    addRecord() {
      this.addVisiable = true;
    },
    handleAddClose() {
      this.addVisiable = false;
    },
    handleAddSuccess() {
      this.addVisiable = false;
      this.$message.success("添加社群活动相关成功！");
      this.fetch();
    },
    onSelectChange (selectedRowKeys) {
      // console.log("selectedRowKeys==>", selectedRowKeys);
      this.selectedRowKeys = selectedRowKeys;
    },
    handleTableChange (pagination, filters, sorter) {
      this.paginationInfo = pagination
      this.fetch({
        ...this.queryParams
      })
    },
    search () {
      // console.log("queryParams ==>", this.queryParams);
      this.fetch({
        ...this.queryParams
      })
    },
    fetch(params = {}) {
      this.loading = true
      if (this.paginationInfo) {
        // 如果分页信息不为空，则设置表格当前第几页，每页条数，并设置查询分页参数
        this.$refs.TableInfo.pagination.current = this.paginationInfo.current
        this.$refs.TableInfo.pagination.pageSize = this.paginationInfo.pageSize
        params.pageSize = this.paginationInfo.pageSize
        params.pageNum = this.paginationInfo.current
      } else {
        // 如果分页信息为空，则设置为默认值
        params.pageSize = this.pagination.defaultPageSize
        params.pageNum = this.pagination.defaultCurrent
      }
      this.$get('activity', {
        ...params
      }).then((r) => {
        let data = r.data;
        const pagination = { ...this.pagination };
        pagination.total = data.total;
        this.pagination = pagination;
        this.loading = false;
        
        let datasource = data.rows;
        let set = new Set();
        for (let item of datasource) {
          for (const id of item.userIdValueList) {
            set.add(id);
          }
        }
        let ids = [...set].join(",");

        this.$get(`user/list/simple/${ids}`).then((resp) => {
          let arr = resp.data.data;

          for (let item of datasource) {
            item.userlist = []
            item.showAll = false;
            for (const id of item.userIdValueList) {
              let one = arr.find((i) => i.userId == id);
              if (one) {
                item.userlist.push(one);
              }
            }
            item.creator = arr.find((i) => i.userId == item.createUserId);
          }

          this.dataSource = datasource;
        });
      })
    },
    batchDelete() {
      if(!this.selectedRowKeys.length) {
        this.$message.warning("请选择需要删除的记录");
      } else {
        let that = this;
        that.$confirm({
          title: "确定删除所选中的记录？",
          content: "当您点击确定按钮后，这些记录将会被彻底删除",
          centered: true,
          onOk () {
            let ids = that.selectedRowKeys.join();
            that.$delete(`activity/${ids}`)
              .then(() => {
                that.$message.success("删除成功！");
                that.selectedRowKeys = [];
                that.fetch();
              })
              .catch(e => console.log("活动删除失败！==> ", e));
          },
          onCancel () {
            that.selectedRowKeys = [];
          }
        })
      }
    },
		reset() {
		  // 取消选中
		  this.selectedRowKeys = [];
		  // 重置分页
		  this.$refs.TableInfo.pagination.current = this.pagination.defaultCurrent;
		  if (this.paginationInfo) {
		    this.paginationInfo.current = this.pagination.defaultCurrent;
		    this.paginationInfo.pageSize = this.pagination.defaultPageSize;
		  }
		  // 重置列过滤器规则
		  this.filteredInfo = null;
		  // 重置列排序规则
		  this.sortedInfo = null;
		  // 重置查询参数
		  this.queryParams = {
		    title: "",
		    communityId: null
		  },
		  this.fetch();
		},
  }
}
</script>

<style lang="less" scoped>
@import "../../../utils/Common.less";
</style>